<template>
  <!-- title="头像" -->
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('other.headportrait')"
    width="470px"
  >
    <el-row>
      <el-col :span="24">
        <el-upload
          ref="coverUpload"
          :class="{ hideAdd: uploadDisabled }"
          name="upload"
          action
          :before-upload="beforePicUpload"
          :http-request="uploadImgAction"
          :file-list="userLogoFileList"
          :on-remove="handleRemoveImgMain"
          :on-change="handleFileChange"
          :auto-upload="true"
          list-type="picture-card"
          accept=".jpg, .png, .jpeg"
        >
          <i class="el-icon-plus" />
          <div slot="tip" class="el-upload__tip mt20">
            <!-- 支持jpg/jpeg/png，最多上传 -->
            {{ $t('reqmsg.$21') }}
            <span class="f_s_16">1</span>
            <!-- 张，不允许超过2MB -->
            {{ $t('reqmsg.$22') }}
          </div>
        </el-upload>
      </el-col>
    </el-row>
    <template #footer>
      <div>
        <el-button type="primary" @click="upload">
          <!-- 保存 -->
          {{ $t('cusManage.Save') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'VabCropper',
    data() {
      return {
        headerUrl: '', //传过来的用户头像
        dialogVisible: false,
        userLogoFileList: [],
        userLogo: '',
        uploadDisabled: false, //主图
        limitImgNumber: 1, //主图数量
      }
    },
    methods: {
      showDialog(url) {
        if (url) {
          this.userLogoFileList = [{ url: url }]
          this.uploadDisabled = true
        }
        this.dialogVisible = true
      },

      ...mapActions({
        setAvatar: 'user/setAvatar',
      }),

      // 上传之前验证
      beforePicUpload(file) {
        this.loading = this.$loading({
          lock: true,
          // text: '图片上传中...',
          text: this.$t('reqmsg.$26'),
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.3)',
        })
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension =
          testmsg === 'jpg' ||
          testmsg === 'JPG' ||
          testmsg === 'png' ||
          testmsg === 'PNG' ||
          testmsg === 'jpeg' ||
          testmsg === 'JPEG'
        const isLt50M = file.size / 1024 / 1024 < 2
        if (!extension) {
          this.loading.close()
          this.$baseMessage(
            // '上传图片只能是jpg/png/jpeg格式',
            this.$t('reqmsg.$23'),
            'error',
            false,
            'erp-hey-message-error'
          )
          return false //必须加上return false; 才能阻止
        }
        if (!isLt50M) {
          this.loading.close()
          this.$baseMessage(
            // '上传图片大小不能超过2MB!',
            this.$t('reqmsg.$24'),
            'error',
            false,
            'erp-hey-message-error'
          )
          return false
        }
        return true
      },

      // 头像上传
      uploadImgAction({ file }) {
        this.utils.ossUpload({ file, type: 'productImg' }).then(({ res }) => {
          if (
            res.requestUrls.length > 0 &&
            res.requestUrls[0].split('?uploadId')
          ) {
            let url = res.requestUrls[0].split('?uploadId')
            this.userLogo = url[0]
          } else {
            this.userLogoFileList = []
            this.userLogo = ''
            this.$baseMessage(
              // '头像上传失败!',
              this.$t('reqmsg.$25'),
              'error',
              false,
              'erp-hey-message-error'
            )
          }
          this.loading.close()
        })
      },
      handleFileChange(file, fileList) {
        this.uploadDisabled = fileList.length >= this.limitImgNumber
      },
      // 删除头像
      handleRemoveImgMain(file, fileList) {
        this.handleFileChange(file, fileList)
        this.userLogoFileList = []
        this.userLogo = ''
      },

      upload() {
        this.$emit('userLogo', this.userLogo)
        this.dialogVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .hideAdd {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
</style>
