import request from '@/core/services/request'

// 用户-根据分页查找列表
export function getUserListPage(data) {
  return request({
    url: '/system/user/listPage',
    method: 'post',
    data,
  })
}

// 新增用户
export function userInsertPO(data) {
  return request({
    url: '/system/user/insertPO',
    method: 'post',
    data,
  })
}

// 更新用户
export function userUpdatePO(data) {
  return request({
    url: '/system/user/updatePO',
    method: 'post',
    data,
  })
}

// 删除用户
// export function userDeleteById(data) {
//   return request({
//     url: '/system/user/deleteById',
//     method: 'post',
//     data,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//     },
//   })
// }

// 批量ID删除
// export function userDeleteByIds(data) {
//   return request({
//     url: '/system/user/deleteByIds',
//     method: 'post',
//     data,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//     },
//   })
// }

// 根据账号获取用户信息
export function userGetUserByAccount(data) {
  return request({
    url: '/system/user/getUserByAccount',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据userid查找
export function userGetDetail(data) {
  return request({
    url: '/system/user/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 批量离职
export function userBulkDepartures(data) {
  return request({
    url: '/system/user/bulkDepartures',
    method: 'post',
    data,
  })
}
