<template>
  <el-dialog
    :visible.sync="dialogAccountVisible"
    width="550px"
    :close-on-click-modal="false"
    @close="closeAccountDialog"
  >
    <template #title>
      <div class="flex_row_y flex_wrap">
        <!-- 新增供应商 -->
        <p class="p_title f_s_16">
          {{ pageType === 'add' ? '新增' : '编辑' }}收款账户
        </p>
      </div>
    </template>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <!-- 户名 -->
      <el-row>
        <el-col :span="24">
          <el-form-item label="户名" prop="accountName">
            <el-input
              v-model="form.accountName"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 账号 -->
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="账号"
            prop="bankAccount"
            :rules="[
              {
                required: true,
                message: $t('placeholder.plsInput'),
                trigger: 'blur',
              },
              {
                validator: checkBankAccount,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model.trim="form.bankAccount"
              placeholder="请输入"
              clearable
              @change="handleChangeAccount"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 开户行 -->
      <el-row>
        <el-col :span="24">
          <el-form-item label="开户行" prop="branchName">
            <bankNameQuery
              ref="bankNameQuery"
              :multiple="false"
              :dialogVisible="dialogAccountVisible"
              :default-bank-name="defaultBankName"
              @bank-name-choose="chooseBankName"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row class="text-center">
        <!-- 确认 -->
        <el-button type="primary" @click="save">确认</el-button>
        <!-- 取消 -->
        <el-button @click="closeAccountDialog">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import bankNameQuery from '@/components/bankNameQuery/index'
  var errorTip = ''
  var checkBankAccount = (rule, value, callback) => {
    if (errorTip) {
      callback(new Error('账号重复'))
    } else {
      callback()
    }
  }
  export default {
    components: {
      bankNameQuery,
    },
    data() {
      return {
        dialogAccountVisible: false,
        pageType: '',
        defaultBankName: '',
        checkBankAccount: '',
        form: {
          userBankId: '',
          accountName: '', // 户名
          bankAccount: '', // 账号
          branchName: '', // 开户行
          cnapsCode: '', // V2.1.4 联行号 个人账户和对公账户必填
        },
        rules: {
          accountName: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ],
          branchName: [
            {
              required: true, //请选择开户行
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
        },
      }
    },
    created() {
      this.checkBankAccount = checkBankAccount
    },
    methods: {
      //判断添加的账号在列表中是否重复
      handleChangeAccount(val) {
        console.log(val)
      },
      // 信息回显
      showMsg(val) {
        this.dialogAccountVisible = true
        this.pageType = 'edit'
        this.form = { ...val }
        this.$nextTick(() => {
          this.defaultBankName = this.form.branchName
          this.$refs.form.clearValidate()
        })
      },

      //新增
      addMsg() {
        this.dialogAccountVisible = true
        this.pageType = 'add'
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },

      chooseBankName(val) {
        this.defaultBankName = val

        if (val) {
          this.form.branchName = val.split('/')[0]
          this.form.cnapsCode = val.split('/')[1]
        } else {
          this.form.branchName = ''
          this.form.cnapsCode = ''
        }
      },

      save() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$emit('saveFn', this.form)
          } else {
            return false
          }
        })
      },

      //关闭弹框
      closeAccountDialog() {
        this.dialogAccountVisible = false
        this.handleClose()
        this.pageType = ''
      },
      handleClose() {
        this.defaultBankName = ''
        this.form = {
          userBankId: '',
          accountName: '', // 户名
          bankAccount: '', // 账号
          branchName: '', // 开户行
        }
      },
    },
  }
</script>

<style lang="scss">
  .account-dialog {
    .el-dialog {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .el-dialog .el-dialog__body {
      max-height: 100%;
      flex: 1;
      -ms-flex: 1 1 auto; /* 兼容IE */
      overflow-y: auto;
      overflow-x: hidden;
    }

    .el-dialog__wrapper {
      /*隐藏ie和edge中遮罩的滚动条*/
      overflow: hidden;
    }
  }
</style>
