var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"visible":_vm.dialogAccountVisible,"width":"550px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogAccountVisible=$event},"close":_vm.closeAccountDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex_row_y flex_wrap"},[_c('p',{staticClass:"p_title f_s_16"},[_vm._v(" "+_vm._s(_vm.pageType === 'add' ? '新增' : '编辑')+"收款账户 ")])])]},proxy:true},{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确认")]),_c('el-button',{on:{"click":_vm.closeAccountDialog}},[_vm._v("取消")])],1)]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"80px"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"户名","prop":"accountName"}},[_c('el-input',{attrs:{"placeholder":"请输入","clearable":""},model:{value:(_vm.form.accountName),callback:function ($$v) {_vm.$set(_vm.form, "accountName", $$v)},expression:"form.accountName"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"账号","prop":"bankAccount","rules":[
            {
              required: true,
              message: _vm.$t('placeholder.plsInput'),
              trigger: 'blur',
            },
            {
              validator: _vm.checkBankAccount,
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"placeholder":"请输入","clearable":""},on:{"change":_vm.handleChangeAccount},model:{value:(_vm.form.bankAccount),callback:function ($$v) {_vm.$set(_vm.form, "bankAccount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.bankAccount"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"开户行","prop":"branchName"}},[_c('bankNameQuery',{ref:"bankNameQuery",attrs:{"multiple":false,"dialogVisible":_vm.dialogAccountVisible,"default-bank-name":_vm.defaultBankName},on:{"bank-name-choose":_vm.chooseBankName}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }