<template>
  <div class="password-check-wrapper">
    <p v-if="showLabel" class="check-label">
      {{ lang === 'en' ? 'Password Strength' : '密码强度' }}:
    </p>
    <el-row :style="style" class="strength-wrapper">
      <el-col :span="colSpan.col" :style="{ backgroundColor: colSpan.color }">
        {{ lang === 'en' ? colSpan.textEn : colSpan.text }}
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { rules } from './rules'
  const PASS_SCORE = 70
  export default {
    name: 'PasswordCheck',
    props: {
      showLabel: {
        type: Boolean,
        default: true,
      },
      height: {
        //高度
        type: Number,
        default: 32,
      },
      bgColor: {
        //默认底色
        type: String,
        default: '#F2F6FC',
      },
      password: {
        type: String,
        value: '',
      },
      lang: {
        type: String,
        default: 'zh',
      },
    },
    data() {
      return {
        score: 0, // 得分
      }
    },
    computed: {
      // 计算样式
      style() {
        return {
          backgroundColor: this.bgColor,
          height: this.height + 'px',
        }
      },
      // 计算宽度和颜色
      colSpan() {
        let score = this.score
        let colSpan = {
          col: 0,
          color: '',
        }
        // if (score == 0) return colSpan

        if (score < 60) {
          colSpan.col = 6
          colSpan.text = '弱'
          colSpan.textEn = 'Weak'
          colSpan.color = '#ff4d4f'
        } else if (59 < score && score < 70) {
          colSpan.col = 12
          colSpan.text = '中'
          colSpan.textEn = 'Medium'
          colSpan.color = '#E6A23C'
        } else if (69 < score && score < 90) {
          colSpan.col = 18
          colSpan.text = '强'
          colSpan.textEn = 'Strong'
          colSpan.color = '#409EFF'
        } else {
          colSpan.col = 24
          colSpan.text = '极强'
          colSpan.textEn = 'Very Strong'
          colSpan.color = '#67C23A'
        }
        return colSpan
      },
    },
    watch: {
      password: {
        // 监听密码执行强度规则校验
        immediate: true,
        handler: function (val) {
          this.getScore(val)
        },
      },
    },
    methods: {
      // 获取得分
      getScore(val) {
        let score = 0
        let bool = false

        if (val) {
          rules.forEach((rule) => {
            score += rule.callback(val)
          })
        }
        this.checkScore(val, score)
        bool = this.score >= PASS_SCORE ? true : false
        this.$emit('isPass', bool)
      },

      checkScore(val, score) {
        const MIDDLE_STRENGTH_SCORE = PASS_SCORE - 1
        // 密码长度小于8且密码强度为强和极强，降级为中
        if (val?.length < 8 && score > MIDDLE_STRENGTH_SCORE) {
          this.score = MIDDLE_STRENGTH_SCORE
        } else {
          this.score = score
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .password-check-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .check-label {
      font-family: 'PingFang Bold';
    }
    .el-row {
      flex: 1;
      margin-left: 10px;
      border-radius: 2px;
    }
  }

  .el-col {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 2px;
  }
</style>
