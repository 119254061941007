<template>
  <el-dialog
    :title="$t('personalCenter.ChangePassword')"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules">
        <el-row :gutter="20">
          <!-- 旧密码 -->
          <el-col :span="24">
            <el-form-item
              prop="oldPassword"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: this.$t('rules.$1'),
                },
              ]"
            >
              <el-input
                v-model.trim="form.oldPassword"
                type="password"
                show-password
                :placeholder="
                  lang == 'en' ? 'please input old password' : '请输入旧密码'
                "
              />
            </el-form-item>
          </el-col>

          <!-- 新密码 -->
          <el-col :span="24">
            <el-form-item
              prop="newPassword"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  min: 8,
                  max: 20,
                  message: this.$t('rules.$10'),
                },
              ]"
            >
              <el-input
                v-model.trim="form.newPassword"
                :placeholder="
                  lang == 'en' ? 'please input new password' : '请输入新密码'
                "
                minlength="8"
                maxlength="20"
                type="password"
                @blur="passwordBlur(form.newPassword)"
                show-password
                onkeyup="this.value = this.value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,'')"
              />
            </el-form-item>
          </el-col>

          <!-- 校验密码强度 -->
          <el-col :span="24" style="margin-top: -5px">
            <password-check
              :lang="lang"
              :password="form.newPassword"
              @isPass="isPass"
            ></password-check>
          </el-col>

          <!-- 确认新密码 -->
          <el-col :span="24">
            <el-form-item
              prop="confirmPassword"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: this.$t('rules.$10'),
                },
              ]"
            >
              <el-input
                v-model.trim="form.confirmPassword"
                :placeholder="
                  lang == 'en' ? 'please confirm new password' : '请确认新密码'
                "
                minlength="8"
                maxlength="20"
                type="password"
                @blur="passwordBlur(form.confirmPassword)"
                show-password
                onkeyup="this.value = this.value.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,'')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="color: #e6a341">
          {{ $t('personalCenter.PasswordMark') }}
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <el-row class="text-center mt10 mb20">
        <el-button
          type="primary"
          style="width: 95%; border-radius: 4px; height: 40px; font-size: 16px"
          @click="save"
        >
          {{ $t('personalCenter.Save') }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { changePassword } from '@/api/public'
  import { mapGetters } from 'vuex'
  import { langMixin } from '@/utils/lang-util'
  import PasswordCheck from '@/components/password-check/index'

  export default {
    name: 'Password',
    mixins: [langMixin],
    components: { PasswordCheck },
    data() {
      return {
        form: {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        },
        rules: {},
        disabled: false, // 校验密码强度
        dialogFormVisible: false,
      }
    },
    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },
    created() {},
    methods: {
      //编辑
      showAddEdit(userId) {
        this.form.userId = userId
        this.dialogFormVisible = true
      },

      // 密码是否通过
      isPass(bool) {
        this.disabled = bool
      },

      //新密码，确认密码判断
      passwordBlur(val) {
        if (
          this.form.newPassword &&
          this.form.confirmPassword &&
          this.form.confirmPassword !== this.form.newPassword
        ) {
          this.$baseMessage(
            this.$t('reqmsg.$3'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
      },

      //关闭
      close() {
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },

      //保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (!this.disabled) {
              return this.$message.warning(this.$t('rules.$9'))
            }

            let form = this.utils.deepCopy(this.form)
            if (form.newPassword !== form.confirmPassword) {
              this.$baseMessage(
                this.$t('reqmsg.$3'),
                'warning',
                false,
                'erp-hey-message-warning'
              )
            }
            let response = await changePassword(form)

            if (response.code === '000000') {
              this.$baseMessage(
                this.$t('reqmsg.$ggg'),
                'success',
                false,
                'erp-hey-message-success'
              )
              this.close()
            }
            setTimeout(() => {
              this.$router.push('/')
            }, 2000)
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    .el-form {
      width: 95%;
      margin: 0 auto;
    }
  }
  ::v-deep {
    .el-select {
      width: 100%;
    }
    .el-dialog {
      border-radius: 4px;
    }
    .el-dialog__header {
      padding: 30px 30px 10px;
      .el-dialog__title {
        font-size: 20px;
      }
    }
    .el-form-item--small.el-form-item {
      margin-bottom: 30px;
    }
    .el-dialog__headerbtn {
      font-size: 30px;
      right: 30px;
    }
  }
</style>
