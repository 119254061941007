var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$t('personalCenter.ChangePassword'),"visible":_vm.dialogFormVisible,"width":"500px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-row',{staticClass:"text-center mt10 mb20"},[_c('el-button',{staticStyle:{"width":"95%","border-radius":"4px","height":"40px","font-size":"16px"},attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('personalCenter.Save'))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"dialog-content"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"oldPassword","rules":[
              {
                required: true,
                trigger: 'blur',
                message: this.$t('rules.$1'),
              } ]}},[_c('el-input',{attrs:{"type":"password","show-password":"","placeholder":_vm.lang == 'en' ? 'please input old password' : '请输入旧密码'},model:{value:(_vm.form.oldPassword),callback:function ($$v) {_vm.$set(_vm.form, "oldPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.oldPassword"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"newPassword","rules":[
              {
                required: true,
                trigger: 'blur',
                min: 8,
                max: 20,
                message: this.$t('rules.$10'),
              } ]}},[_c('el-input',{attrs:{"placeholder":_vm.lang == 'en' ? 'please input new password' : '请输入新密码',"minlength":"8","maxlength":"20","type":"password","show-password":"","onkeyup":"this.value = this.value.replace(/[\\u4E00-\\u9FA5]|[\\uFE30-\\uFFA0]/g,'')"},on:{"blur":function($event){return _vm.passwordBlur(_vm.form.newPassword)}},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.newPassword"}})],1)],1),_c('el-col',{staticStyle:{"margin-top":"-5px"},attrs:{"span":24}},[_c('password-check',{attrs:{"lang":_vm.lang,"password":_vm.form.newPassword},on:{"isPass":_vm.isPass}})],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"confirmPassword","rules":[
              {
                required: true,
                trigger: 'blur',
                message: this.$t('rules.$10'),
              } ]}},[_c('el-input',{attrs:{"placeholder":_vm.lang == 'en' ? 'please confirm new password' : '请确认新密码',"minlength":"8","maxlength":"20","type":"password","show-password":"","onkeyup":"this.value = this.value.replace(/[\\u4E00-\\u9FA5]|[\\uFE30-\\uFFA0]/g,'')"},on:{"blur":function($event){return _vm.passwordBlur(_vm.form.confirmPassword)}},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.confirmPassword"}})],1)],1)],1),_c('el-row',{staticStyle:{"color":"#e6a341"}},[_vm._v(" "+_vm._s(_vm.$t('personalCenter.PasswordMark'))+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }