import request from '@/core/services/request'

// 供应商管理

//根据分页查找列表
export function supplierListPage(data) {
  return request({
    url: '/merchants/supplier/listPage',
    method: 'post',
    data,
  })
}
//供应商性质-查找所有
export function supplierNatureListAll(data) {
  return request({
    url: '/merchants/supplierNature/listAll',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//供应商类型-查找所有
export function supplierTypeListAll(data) {
  return request({
    url: '/merchants/supplierType/listAll',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//付款方式下拉回显
export function paymentListPage(data) {
  return request({
    url: '/merchants/payment/listForSelect',
    method: 'post',
    data,
  })
}
// 产品列表-根据产品名称或编码查询详情
export function detailByCodeOrName(data) {
  return request({
    url: '/product/product/getDetailByCodeOrName2',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//新增供应商
export function supplierInsertPO(data) {
  return request({
    url: '/merchants/supplier/insertPO',
    method: 'post',
    data,
  })
}
//加入黑名单
export function supplierAddBlacklist(data) {
  return request({
    url: '/merchants/supplier/addBlacklist',
    method: 'post',
    data,
  })
}
//根据id查找供应商信息（编辑）
export function supplierDetail(data) {
  return request({
    url: '/merchants/supplier/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//根据id查找供应商信息（编辑 新的）
export function supplierDetailNew(data) {
  return request({
    url: '/merchants/supplier/getDetailNew',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//根据id查找企业认证信息
export function supplierAttestationGetDetail(data) {
  return request({
    url: '/merchants/supplierAttestation/getDetailBySupplierId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 根据id查找
export function getDetail(data) {
  return request({
    url: '/merchants/supplier/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 编辑供应商
export function updatePO(data) {
  return request({
    url: '/merchants/supplier/updatePO',
    method: 'post',
    data,
  })
}
// 企业信息新增
export function supplierAttestationInsertPO(data) {
  return request({
    url: '/merchants/supplierAttestation/insertPO',
    method: 'post',
    data,
  })
}
// 供应商订单信息
export function listInfoForSupplier(data) {
  return request({
    url: '/order/purchaseOrder/listInfoForSupplier',
    method: 'post',
    data,
  })
}
// 供应商订单信息
export function supplierListForByParam(data) {
  return request({
    url: '/merchants/supplier/listForByParam',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 保存供应商-录入中-新增
export function supplierSavePO(data) {
  return request({
    url: '/merchants/supplier/savePO',
    method: 'post',
    data,
  })
}
// 保存供应商-录入中-更新
export function supplierUpdatePO(data) {
  return request({
    url: '/merchants/supplier/updatePO',
    method: 'post',
    data,
  })
}

// 查询采购单审核管理中的供应商状态
export function supplierForAuditor(data) {
  return request({
    url: '/merchants/supplier/getSupplierForAuditor',
    method: 'post',
    data,
  })
}

//校验供应商名称是否重复(true:重复 false不重复)
export function checkSupplierName(supplierName) {
  return request({
    url: '/merchants/supplier/checkSupplierName',
    method: 'post',
    params: {
      supplierName,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//获取开户行
export function getBankList(data) {
  return request({
    url: '/finance/cbsCnaps/queryCnaps',
    method: 'post',
    data,
  })
}
