const UPPER_CASE_RE = /[A-Z]+/g
const LOWER_CASE_RE = /[a-z]+/g
const NUMBER_RE = /\d+/g
const SPECIAL_REG = /[`~=!@#$%^&*()_+<>?:"{},.\/;'[\]]+/g
export const rules = [
  {
    type: 'LENGTH',

    callback: (value) => {
      let score = 0
      if (value.length < 5) {
        score = 5
      } else if (value.length < 8) {
        score = 10
      } else if (value.length > 7) {
        score = 25
      }
      return score
    },
  },
  {
    type: 'LETTER',
    callback: (value) => {
      let upperArray = value.match(UPPER_CASE_RE)
      let lowerArray = value.match(LOWER_CASE_RE)
      let score = 0
      if (upperArray && lowerArray) {
        score = 25
      } else if ((!lowerArray && upperArray) || (!upperArray && lowerArray)) {
        score = 10
      }
      return score
    },
  },
  {
    type: 'NUMBER',
    callback: (value) => {
      let numArrStr = (value.match(NUMBER_RE) || []).join('')
      let numberArr = [...new Set(numArrStr)]
      let score = 0
      if (numberArr.length == 1) {
        score = 10
      } else if (numberArr.length > 1) {
        score = 25
      }
      return score
    },
  },
  {
    type: 'SPECIAL_CHARACTERS',
    callback: (value) => {
      let score = 0
      let matched = value.match(SPECIAL_REG)
      if (matched) {
        matched = [...new Set(matched.join('').split(''))]
        if (matched.length < 2) {
          score = 10
        } else {
          score = 25
        }
      }
      return score
    },
  },
  {
    type: 'COMBINE',
    callback: (value) => {
      let hasNumber = value.match(NUMBER_RE)
      let hasSpecail = value.match(SPECIAL_REG)
      let hasUpper = value.match(UPPER_CASE_RE)
      let hasLower = value.match(LOWER_CASE_RE)
      let hasLetter = hasUpper || hasLower // 字母
      let score = 0
      let combineTypes = [hasNumber, hasSpecail, hasLetter].filter(
        (type) => type
      )
      if (combineTypes.length == 2) {
        score = 10
      }
      if (combineTypes.length == 3) {
        score = 20
      }
      return score
    },
  },
]
