<template>
  <el-row class="person-center">
    <el-row class="content-box">
      <div class="content">
        <el-row
          class="header"
          type="flex"
          align="middle"
          justify="space-between"
        >
          <!-- <span class="title">基本信息</span> -->
          <span>{{ $t('personalCenter.BasicInformation') }}</span>
          <i class="el-icon-close f_s_18 c_pointer" @click="close"></i>
        </el-row>

        <!-- 基本信息 -->
        <el-row class="user-img" type="flex" align="bottom">
          <el-row class="headeurl">
            <el-image
              v-if="userForm.headUrl"
              :src="userForm.headUrl"
              class="user-avatar c_pointer"
              @click.native="openDialog()"
            />
            <span
              v-else
              class="iconfont icon-person c_pointer user-avatar"
              @click="openDialog"
            ></span>
            <div class="mock" @click="openDialog(userForm.headUrl)">
              <!-- 更换头像-->
              {{ $t('personalCenter.BasicInformation') }}
            </div>
          </el-row>

          <el-row class="ml20 user-name-content">
            <!-- <label>登录名</label> -->
            <label>{{ $t('personalCenter.LoginName') }}</label>
            <span class="ml10 name">{{ loginName }}</span>
            <span
              class="span-tag ml10"
              v-for="(item, index) of roleNames"
              :key="index"
            >
              {{ item }}
            </span>
          </el-row>
        </el-row>
        <el-form
          class="mt30"
          ref="userForm"
          :rules="userRules"
          :model="userForm"
          label-width="80px"
          labelPosition="top"
        >
          <el-row :gutter="30">
            <el-col :span="8">
              <el-form-item
                prop="userName"
                :label="$t('personalCenter.Name')"
                :rules="[
                  {
                    required: true,
                    message: this.$t('placeholder.plsInputName'),
                  },
                ]"
              >
                <el-input
                  maxlength="50"
                  v-model="userForm.userName"
                  :placeholder="lang == 'en' ? 'please input' : '请输入'"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 生日 -->
              <el-form-item
                :label="$t('personalCenter.Birthday')"
                prop="birthday"
              >
                <el-date-picker
                  v-model="userForm.birthday"
                  :picker-options="pickerOptions"
                  type="date"
                  :placeholder="lang == 'en' ? 'Year/Month/Day' : '年/月/日'"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('personalCenter.Age')" prop="age">
                <el-input
                  readonly
                  type="Number"
                  v-model="userForm.age"
                  :placeholder="lang == 'en' ? 'please input' : '请输入'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="8">
              <el-form-item :label="$t('personalCenter.Gender')" prop="sex">
                <el-select
                  v-model="userForm.sex"
                  :placeholder="$lang('请选择')"
                  class="w100"
                >
                  <el-option :label="lang == 'en' ? 'Male' : '男'" value="男" />
                  <el-option
                    :label="lang == 'en' ? 'Female' : '女'"
                    value="女"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('personalCenter.IDNumber')"
                prop="idCard"
              >
                <el-input
                  v-model="userForm.idCard"
                  :maxlength="18"
                  :placeholder="lang == 'en' ? 'please input' : '请输入'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button type="primary" @click="updateUserMsg" class="mt10">
          <!-- {{ $lang('更新资料') }} -->
          {{ $t('personalCenter.UpdateInformation') }}
        </el-button>

        <!-- 账号安全  -->
        <el-row
          class="header mt30 mb5"
          type="flex"
          align="middle"
          justify="space-between"
        >
          <!-- <span class="title">{{ $lang('账号安全') }}</span> -->
          <span class="title">
            {{ $t('personalCenter.AccountSecurity') }}
          </span>
        </el-row>
        <el-row
          type="flex"
          align="middle"
          justify="space-between"
          class="other-msg other-msg-floor"
        >
          <el-row>
            <label>{{ $t('personalCenter.LoginPassword') }}</label>
            <span>{{ $t('personalCenter.PasswordSetted') }}</span>
          </el-row>
          <el-button @click="updatePwd">
            {{ $t('personalCenter.ChangePassword') }}
          </el-button>
        </el-row>
        <el-row type="flex" align="middle" class="other-msg">
          <el-row>
            <!-- <label>{{ $lang('手机号') }}</label> -->
            <label>{{ $t('personalCenter.PhoneNumber') }}</label>
            <span>{{ telephone }}</span>
          </el-row>
        </el-row>
        <el-row type="flex" align="middle" class="other-msg">
          <el-row>
            <label>{{ $t('personalCenter.Mail') }}</label>
            <span>{{ email }}</span>
          </el-row>
        </el-row>
      </div>
      <!-- 收款账号 -->
      <el-col class="blank-content">
        <div class="blank-content-top">
          <h3>{{ $t('personalCenter.PaymentAccountNumber') }}</h3>
          <el-button type="primary" size="small" @click="addBankAccount">
            {{ $t('personalCenter.Add') }}
          </el-button>
        </div>
        <el-table :data="blankData" style="width: 100%">
          <el-table-column
            type="index"
            align="center"
            width="50px"
            :label="$t('personalCenter.SerialNumber')"
          ></el-table-column>
          <el-table-column
            prop="accountName"
            :label="$t('personalCenter.AccountName')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="bankAccount"
            :label="$t('personalCenter.Account')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="branchName"
            :label="$t('personalCenter.AccountOpeningBank')"
            align="center"
          ></el-table-column>
          <el-table-column
            :label="$t('personalCenter.Operate')"
            align="center"
            width="120px"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="editBank(scope.row, 1)"
              >
                {{ $t('personalCenter.Edit') }}
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="editBank(scope.row, 2)"
              >
                {{ $t('personalCenter.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!-- 头像 -->
    <vab-cropper ref="vabCropper" @userLogo="userLogo" />

    <!-- 更换密码 -->
    <PasswordModel ref="PasswordModel" />

    <!-- 收款账号 -->
    <bankAccount ref="bankAccount" @saveFn="saveFn" />
  </el-row>
</template>

<script>
  import { isIdCard } from '@/utils/validate'
  import PasswordModel from './components/password.vue'
  import VabCropper from '@/components/VabCropper'
  import { mapActions, mapGetters } from 'vuex'
  import { userGetDetail } from '@/api/organization-user'
  import { updatePersonalCenter } from '@/api/public'
  import { langMixin } from '@/utils/lang-util'
  import bankAccount from './components/bankAccount'
  import { UserInteractor } from '@/core'
  export default {
    name: 'PersonalCenter',
    mixins: [langMixin],
    components: { VabCropper, PasswordModel, bankAccount },
    data() {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() //选当前时间之前的时间
          },
        },
        userForm: {
          userName: '', //用户姓名
          birthday: '', //生日
          age: '', //年龄
          sex: '', //性别
          idCard: '', //身份证号
        },
        userRules: {},
        loginName: '', //登录名
        telephone: '', //手机号
        email: '', //邮箱
        roleNames: [], //角色名称
        blankData: [],
      }
    },
    watch: {
      'userForm.birthday': {
        immediate: true,
        deep: true,
        handler(value) {
          if (!value) return
          this.getAge(value)
        },
      },
    },
    computed: {
      ...mapGetters({
        avatar: 'user/avatar',
        userInfo: 'user/userInfo',
        // lang: 'settings/language',
      }),
    },
    mounted() {
      this.getUserGetDetail()
    },
    created() {
      this.getListFn()
    },
    methods: {
      //获取用户信息
      async getUserGetDetail() {
        let response = await userGetDetail({ userId: this.userInfo.userId })

        if (response?.code === '000000') {
          this.userForm.userName = response.data.userName
          this.userForm.birthday = response.data.birthday
          this.userForm.age = response.data.age
          this.userForm.sex = response.data.sex
          this.userForm.idCard = response.data.idCard
          this.userForm.userId = response.data.userId
          this.userForm.loginName = response.data.loginName
          this.userForm.tenantId = response.data.tenantId
          this.userForm.headUrl = response.data.headUrl
          this.loginName = response.data.loginName
          this.roleNames = response.data.roleNames
          this.telephone = this.noPassByMobile(response.data.telephone)
          this.email = this.noPassByEmail(response.data.email)
          this.$store.commit('user/setAvatar', response.data.headUrl)
        }
      },

      //手机号展示
      noPassByMobile(str) {
        if (str != null && str != undefined) {
          let pat = /(\d{3})\d*(\d{4})/
          return str.replace(pat, '$1****$2')
        } else {
          return ''
        }
      },

      //邮箱展示
      noPassByEmail(str) {
        let front = str.split('@')[0]
        let frontlength = front.length - 2
        let star = '' //邮箱显示星星
        for (let i = 1; i < frontlength; i++) {
          star += '*'
        }
        if (str != null && str != undefined) {
          let pat = /(\d{1})\d*(\d{1})/
          let test = front.replace(pat, `$1${star}$2`)
          return test + '@' + str.split('@')[1]
        } else {
          return ''
        }
      },

      changeActive() {
        this.uploadTip = true
      },
      removeActive() {
        this.uploadTip = false
      },

      openDialog(url) {
        this.$refs['vabCropper'].showDialog(url)
      },

      //上传头像
      userLogo(img) {
        this.userForm.headUrl = img
        this.$forceUpdate()
      },
      clearValidate() {
        this.$refs.userForm.clearValidate()
      },
      //更新资料
      updateUserMsg() {
        let form = JSON.parse(JSON.stringify(this.userForm))
        this.$refs.userForm.validate(async (valid) => {
          if (form.idCard && !isIdCard(form.idCard)) {
            return this.$baseMessage(
              this.$t('reqmsg.$1'),
              'warning',
              false,
              'erp-hey-message-warning'
            )
          }
          updatePersonalCenter(form).then((res) => {
            if (res.code === '000000') {
              this.getUserGetDetail()
              return this.$baseMessage(
                this.$t('reqmsg.$2'),
                'success',
                false,
                'erp-hey-message-success'
              )
            }
          })
        })
      },

      //更换密码
      updatePwd() {
        this.$refs['PasswordModel'].showAddEdit(this.userForm.userId)
      },

      //关闭页面
      close() {
        this.$router.go(-1)
      },

      //计算年龄
      getAge() {
        let birthdays = new Date(this.userForm.birthday.replace(/-/g, '/'))
        let d = new Date()
        let age =
          d.getFullYear() -
          birthdays.getFullYear() -
          (d.getMonth() < birthdays.getMonth() ||
          (d.getMonth() == birthdays.getMonth() &&
            d.getDate() < birthdays.getDate())
            ? 1
            : 0)
        this.$set(this.userForm, 'age', age)
      },
      // 获取收款账号
      async getListFn() {
        let res = await UserInteractor.getListApi()
        if (res?.code === '000000') {
          this.blankData = res?.data
        } else {
          this.blankData = []
        }
      },

      // 新增账号
      addBankAccount() {
        this.$refs.bankAccount.addMsg()
      },
      // 新增保存
      async saveFn(obj) {
        const res = await UserInteractor.addOrUpdateApi(obj)
        if (res?.code === '000000') {
          this.$refs.bankAccount.closeAccountDialog()
          this.$message.success('操作成功！')
          this.getListFn()
        }
      },
      //编辑收款账号
      editBank(row, num) {
        if (num === 1) {
          this.$refs.bankAccount.showMsg(row)
        } else {
          this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              let data = { userBankId: row.userBankId }
              this._deleteUserBank(data)
            })
            .catch(() => {})
        }
      },
      async _deleteUserBank(data) {
        const res = await UserInteractor.deleteUserBankApi(data)
        if (res?.code === '000000') {
          this.$message.success('删除成功')
          this.getListFn()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .person-center {
    .content-box {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      background-color: #f6f8f9;
      overflow-y: auto;
      padding-bottom: 80px;
      .blank-content {
        width: 60%;
        // height: calc(100vh - 720px);
        background: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 15px 30px;
        border-radius: 6px;
        // overflow-y: auto;
        .blank-content-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
        }
      }
      .content {
        width: 60%;
        height: 650px;
        background: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 15px 30px;
        border-radius: 6px;
        margin-bottom: 10px;
        .header {
          border-bottom: 1px solid #ededed;
          padding-bottom: 15px;
          margin-bottom: 25px;
          .title {
            font-family: 'PingFang Bold';
          }
        }
        .user-img {
          .user-name-content {
            .name {
              font-size: 15px;
              color: #000;
            }
            .span-tag {
              background: #ff854c;
              color: #fff;
              font-size: 12px;
              padding: 3px 5px;
              border-radius: 3px;
            }
          }
        }
        .other-msg {
          padding-top: 22px;
          padding-bottom: 22px;
          border-bottom: 1px solid #ededed;
          span {
            margin-left: 15px;
          }
        }
        .other-msg-floor {
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }
    }
  }
  .headeurl {
    position: relative;
    cursor: pointer;
    .mock {
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      text-align: center;
      line-height: 80px;
      background: rgba(0, 0, 0, 0.2);
      color: #fff;
      display: none;
    }
  }
  .headeurl:hover {
    .mock {
      display: block;
    }
  }
  .user-avatar {
    display: inline-block;
    width: 80px;
    height: 80px;

    text-align: center;
    line-height: 80px;
    border-radius: 50%;
    background-size: 100% 100%;
    border: 1px solid #ededed;
  }
  .w80 {
    width: 80%;
  }
</style>
